import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function About() {
  return (
    <Box
      bgPosition='center'
      bgRepeat='no-repeat'
      display='flex'
      justifyContent='center'
      alignItems='center'
      padding='0.5rem'
      minH='100%'
      id='about'
    >
      <Box
        display='flex'
        flexDirection='column'
        textAlign='center'
        w='clamp(300px, 80%, 900px)'
      >
        <Text fontSize='2.5rem'>About</Text>
        <Text fontSize='1rem'>
          Waves is BITS Goa's annual cultural fest and is one of the most highly
          anticipated fests in the country. It has seen an exponential growth in
          the number of attendees and is one of India's fastest growing fests.
          The fact that it takes place in perhaps the most vibrant location of
          the country, Goa only adds to the beautiful vibe the fest creates. The
          fest celebrates music, dance and art and has multiple inter-college
          competitions where students can display their skills and immerse
          themselves in the beauty of the fest.
        </Text>
        <Box
          w='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          position='relative'
        >
          <iframe
            src='https://www.youtube.com/embed/NsVoh5woOTg'
            style={{
              margin: '1rem',
            }}
            width='450'
            height='250'
            title="Waves'19 Official Aftermovie | BITS Goa | Ft. The Local Train, Amit Trivedi & Tony Junior"
            frameborder='0'
            allowfullscreen
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
