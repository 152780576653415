import React from 'react';
import { useState } from 'react';
import { Box, keyframes, Image, useMediaQuery } from '@chakra-ui/react';
import loaderBg from '../assets/images/loader-bg-vert.jpg';
import wavesA from '../assets/images/waves-A.png';
import wavesTitle from '../assets/images/wavesTitle.png';

const spinXlarge = keyframes`
  from {  transform: rotate(0deg) scale(1.3) ; }
  to { transform: rotate(360deg) scale(1.3); }
`;

const spinLarge = keyframes`
  from { transform: rotate(0deg) scale(1.4); }
  to { transform: rotate(360deg) scale(1.4) ; }
`;

const spinSmall = keyframes`
  from { transform: rotate(0deg) scale(2.3); }
  to { transform: rotate(360deg) scale(2.3) ; }
`;

const spinToFadeXl = keyframes`
  from { transform: rotate(0deg) scale(1.3); }
  to { transform: rotate(360deg) scale(1.7); opacity: 0; }
`;

const spinToFadeL = keyframes`
  from { transform: rotate(0deg) scale(1.4); }
  to { transform: rotate(360deg) scale(1.9); opacity: 0; }
`;

const spinToFadeS = keyframes`
  from { transform: rotate(0deg) scale(2.3); }
  to { transform: rotate(360deg) scale(2.8); opacity: 0; }
`;

const bounce = keyframes`
  from { transform: translateY(0px); }
  to { transform: translateY(20px); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fold = keyframes`
  from { transform: perspective(800px) rotateX(0deg) ; }
  to { transform: perspective(800px) rotateX(90deg) ; }
`;

function Loader() {
  const [loaded, setLoaded] = useState(false);
  const [showName, setShowName] = useState(false);
  const [hidebg, setHidebg] = useState(false);
  const [foldDOM, setFoldDOM] = useState(false);
  const [secondAnimation, setSecondAnimation] = useState(false);
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  //timer substitutes for loading state
  setTimeout(() => {
    setSecondAnimation(true);
  }, 3000);

  const handleClick = () => {
    if (loaded && !secondAnimation) {
      setSecondAnimation(true);
    }
  };
  const spinAnimation = `${
    isLargerThan1024 ? spinXlarge : isLargerThan800 ? spinLarge : spinSmall
  } infinite 3.5s linear`;

  const spinToFadeAnimation = `${
    isLargerThan1024
      ? spinToFadeXl
      : isLargerThan800
      ? spinToFadeL
      : spinToFadeS
  } 1 3s linear`;

  const fadeInAnimation = `${fadeIn} 1 2s linear`;

  const bounceAnimation = `${bounce} 1s ease-in-out infinite alternate`;

  const foldAnimation = `${fold} 1 2s linear`;
  return (
    <>
      <Box
        transformOrigin='top'
        position='fixed'
        top='0'
        left='0'
        width='100vw'
        height='100vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
        bg='white'
        zIndex='1000'
        animation={foldDOM ? foldAnimation : ''}
        sx={{ 'animation-fill-mode': 'forwards' }}
      >
        {hidebg ? null : (
          <Image
            transform={
              isLargerThan1024
                ? 'scale(1.3)'
                : isLargerThan800
                ? 'scale(1.35)'
                : 'scale(2.3)'
            }
            src={loaderBg}
            animation={secondAnimation ? spinToFadeAnimation : spinAnimation}
            onAnimationEnd={() => {
              if (secondAnimation) {
                setShowName(true);
                setHidebg(true);
              }
            }}
          />
        )}

        <Image
          onClick={handleClick}
          h={`${
            isLargerThan1024 ? '300px' : isLargerThan800 ? '225px' : '150px'
          }`}
          src={wavesA}
          position='absolute'
          animation={secondAnimation ? '' : loaded ? bounceAnimation : ''}
        />
        {showName ? (
          <Image
            onClick={handleClick}
            h={`${
              isLargerThan1024 ? '200px' : isLargerThan800 ? '150px' : '100px'
            }`}
            marginLeft='8.5%'
            marginBottom='32px'
            src={wavesTitle}
            position='absolute'
            animation={secondAnimation ? fadeInAnimation : ''}
            onAnimationEnd={() => {
              setFoldDOM(true);
            }}
          />
        ) : null}
      </Box>
    </>
  );
}

export default Loader;
