import {
  Box,
  Link,
  Text,
  Heading,
  Image,
  VStack,
  SimpleGrid,
  Grid,
} from '@chakra-ui/react';
import React from 'react';

// import sponsorBg from '../assets/images/sponsors/bg.png'

// Sponsors
import logoKTM from '../assets/images/sponsors/ktm.png';
import logoMyntra from '../assets/images/sponsors/myntra.png';
import logoTime from '../assets/images/sponsors/time.png';
import logoIxigo from '../assets/images/sponsors/ixigo.png';
import logoSbi from '../assets/images/sponsors/sbi.png';
import logoDempo from '../assets/images/sponsors/dempo.png';
import logoHdfc from '../assets/images/sponsors/hdfc.png';
import logoCoolberg from '../assets/images/sponsors/coolberg.png';
import logoRenaissance from '../assets/images/sponsors/renaissance.png';
import logoTowardsABetterYou from '../assets/images/sponsors/towardsabetteryou.png';
import logoBluelearn from '../assets/images/sponsors/bluelearn.png';
import logoBeardo from '../assets/images/sponsors/beardo.png';
import logoGatsby from '../assets/images/sponsors/gatsby.png';
import logoReals from '../assets/images/sponsors/real.png';
import logoRoposo from '../assets/images/sponsors/roposo.png';
import logoAbhibus from '../assets/images/sponsors/abhibus.png';
import logoHikerwolf from '../assets/images/sponsors/hikerwolf.png';
import logoMakino from '../assets/images/sponsors/makino.png';
import logoPlum from '../assets/images/sponsors/plum.png';
import logoPrincess from '../assets/images/sponsors/princess.jpg';

// Media Partners
import logoAapkaTimes from '../assets/images/sponsors/aapkatimes.png';
import logoAapkaJob from '../assets/images/sponsors/aapkajob.png';
import logoAwesong from '../assets/images/sponsors/awesong2.png';
import logoBoldsky from '../assets/images/sponsors/boldsky.png';
import logoCampusKarma from '../assets/images/sponsors/campus karma.jpeg';
import logoCampusTimesPune from '../assets/images/sponsors/campus times pune.png';
import logoCampusVarta from '../assets/images/sponsors/campus varta.png';
import logoCurriculum from '../assets/images/sponsors/curriculum mag.png';
import logoDaisworld from '../assets/images/sponsors/daisworld.png';
import logoEdtimes from '../assets/images/sponsors/edtimes2.png';
import logoFashionHerald from '../assets/images/sponsors/Fashion Herald.jpeg';
import logoFestsinfo from '../assets/images/sponsors/festsinfo.png';
import logoFrontlines from '../assets/images/sponsors/frontlines media.png';
import logoGrooveNexus from '../assets/images/sponsors/GrooveNexus_Logo.jpg';
import logoHumaraMovie from '../assets/images/sponsors/humaramovie.jpeg';
import logoIdeapreneur from '../assets/images/sponsors/ideapreneurdia.png';
import logoIndiaCollegeFest from '../assets/images/sponsors/india college fest.png';
import logoIndiaEducation from '../assets/images/sponsors/india education.png';
import logoInsightone from '../assets/images/sponsors/insightone.png';
import logoInterviewBuddy from '../assets/images/sponsors/Interview buddy.png';
import logoKnowafest from '../assets/images/sponsors/knowafest.jpeg';
import logoKoc from '../assets/images/sponsors/koc.png';
import logoLawctopus from '../assets/images/sponsors/Lawctopus-logo.svg';
import logoNewsreach from '../assets/images/sponsors/newsreach2.png';
import logoOhCampus from '../assets/images/sponsors/ohcampus.png';
import logoPaisaPani from '../assets/images/sponsors/PaisaPani.png';
import logoRDX from '../assets/images/sponsors/rdx goa.png';
import logoStartupNama from '../assets/images/sponsors/startupnama.png';
import logoTheGoan from '../assets/images/sponsors/the goan 2.png';
import logoVivaGoa from '../assets/images/sponsors/viva goa 2.jpg';
import logoGaana from '../assets/images/sponsors/gaana.jpg';
import logoFilterCopy from '../assets/images/sponsors/filter-copy.png';
import logoClout from '../assets/images/sponsors/clout.jpeg';
import logoMTV from '../assets/images/sponsors/MTV.png';
import logoGrapeVine from '../assets/images/sponsors/grapevine.jpg';
import logoZeeNews from '../assets/images/sponsors/zee news.png';
import logoToTheCulture from '../assets/images/sponsors/to-the-culture.png';

const Sponsors = () => {
  return (
    <>
      <VStack minH={'100%'} paddingY={'1rem'} id='sponsors'>
        <Heading fontFamily='BlackChancery'>Title Sponsors</Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            // lg: 'repeat(2, 1fr)',
            // xl: 'repeat(3, 1fr)',
          }}
          spacing={'1rem'}
          autoRows={'1fr'}
        >
          <Sponsor
            src={logoMyntra}
            alt={'Myntra'}
            href={'https://www.myntra.com/stylecast'}
            desc={'Official Fashion Partner'}
          />
          <Sponsor
            src={logoKTM}
            alt={'KTM'}
            href={'https://www.ktmindia.com/'}
            desc={'Official Biking Partner'}
          />
        </Grid>
      </VStack>
      <VStack minH={'100%'} paddingY={'1rem'}>
        <Heading fontFamily='BlackChancery'>Sponsors</Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
            xl: 'repeat(3, 1fr)',
          }}
          spacing={'1rem'}
          autoRows={'1fr'}
        >
          {/* Sponsors */}
          <Sponsor
            src={logoTime}
            alt={'T.I.M.E'}
            href={'https://www.time4education.com'}
            desc={'Official higher studies partner'}
          />
          <Sponsor
            src={logoIxigo}
            alt={'Ixigo'}
            href={'https://www.ixigo.com/'}
            desc={'Official Travel Partner'}
          />
          <Sponsor
            src={logoSbi}
            alt={'SBI'}
            href={'https://sbi.co.in/'}
            desc={'Official banking partner'}
          />
          <Sponsor
            src={logoDempo}
            alt={'Dempo'}
            href={'https://www.dempos.com/csr/dempo-charities-trust/'}
            desc={'Official Charity partner'}
          />
          <Sponsor
            src={logoHdfc}
            alt={'HDFC'}
            href={'https://www.hdfcbank.com/personal/pay/cards/credit-cards'}
            desc={'Festival partner'}
          />
          <Sponsor
            src={logoRoposo}
            alt={'Roposo'}
            href={'https://www.roposo.com/'}
            desc={'Live entertainment partner'}
          />
          <Sponsor
            src={logoCoolberg}
            alt={'Coolberg'}
            href={'https://coolberg.in/'}
            desc={'Festival partner'}
          />
          <Sponsor
            src={logoRenaissance}
            alt={'Renaissance Educare'}
            href={'https://therenaissance.co.in/'}
            desc={'Festival partner'}
          />
          <Sponsor
            src={logoAbhibus}
            alt={'Abhibus'}
            href={'https://www.abhibus.com/'}
            desc={'Official Bus partner'}
          />
          <Sponsor
            src={logoMakino}
            alt={'Makino'}
            href={'https://www.recornfoods.com/'}
            desc={'Festival partner'}
          />
          <Sponsor
            src={logoHikerwolf}
            alt={'Hikerwolf'}
            href={'https://hikerwolf.com/'}
            desc={'Festival partner'}
          />
          <Sponsor
            src={logoTowardsABetterYou}
            alt={'Towards A Better You'}
            href={'https://www.towardsabetteryou.in/'}
            desc={'Official Mental Health partner '}
          />
          <Sponsor
            src={logoBluelearn}
            alt={'Bluelearn '}
            href={'https://www.bluelearn.in/'}
            desc={'Official Community Partner'}
          />
          <Sponsor
            src={logoBeardo}
            alt={'Beardo'}
            href={'https://beardo.in/'}
            desc={"Men's Grooming partner for Mr & Ms Waves "}
          />
          <Sponsor
            src={logoGatsby}
            alt={'Gatsby'}
            href={'https://gatsbyindia.com/'}
            desc={'Styling partner of Searock '}
          />
          <Sponsor
            src={logoKoc}
            alt={'Kingdom of Cuisine'}
            href={''}
            desc={'Official Food Partner'}
          />
          <Sponsor
            src={logoReals}
            alt={'Reals'}
            href={'https://www.facebook.com/realgroupgoa/'}
            desc={'Official Hydration Partner '}
          />
          <Sponsor
            src={logoPlum}
            alt={'Plum Bodylobin'}
            href={'https://plumgoodness.com/collections/plum-bodylovin'}
            desc={'Bath and Body Partner'}
          />
          <Sponsor
            src={logoPrincess}
            alt={'Princess'}
            href={''}
            desc={'Festival Partner'}
          />
        </Grid>
        {/* Media Partners */}
      </VStack>
      <VStack minH={'100%'} paddingY={'1rem'}>
        <Heading fontFamily='BlackChancery'>Media Partners</Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(4, 1fr)',
          }}
          spacing={'1rem'}
          autoRows={'1fr'}
        >
          <MediaSponsor
            src={logoMTV}
            alt={'MTv'}
            href={'https://www.mtv.com/'}
            desc={'Official Media Partner'}
          />
          <MediaSponsor
            src={logoGrapeVine}
            alt={'Grapevine'}
            href={'https://www.thegrapevine.co.in/'}
            desc={'Official Youth Partner'}
          />
          <MediaSponsor
            src={logoZeeNews}
            alt={'Zee News'}
            href={'https://zeenews.india.com/'}
            desc={'Official Media Partner'}
          />
          <MediaSponsor
            src={logoFilterCopy}
            alt={'Filtercopy'}
            href={'https://www.youtube.com/c/filtercopy'}
            desc={'Official Entertainment Partner'}
          />
          <MediaSponsor
            src={logoClout}
            alt={'Clout'}
            href={'https://www.instagram.com/cloutpocketaces/?hl=en'}
            desc={'Official Talent Partner'}
          />
          <MediaSponsor
            src={logoGaana}
            alt={'Gaana'}
            href={'https://gaana.com/'}
            desc={'Official Music Media Partner of Searock'}
          />
          <MediaSponsor
            src={logoAapkaTimes}
            alt={'Aapka Times'}
            href={'https://www.aapkatimes.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoAapkaJob}
            alt={'AppkaJob'}
            href={'https://aapkajob.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoAwesong}
            alt={'Awesong'}
            href={'https://awesong.in/'}
            desc={'Associate Media Partner and Media Partner of Jukebox Hindi'}
          />
          <MediaSponsor
            src={logoBoldsky}
            alt={'Boldsky'}
            href={'https://www.boldsky.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoCampusKarma}
            alt={'Campus Karma'}
            href={'https://www.campuskarma.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoCampusTimesPune}
            alt={'Campus Times Pune'}
            href={'https://www.campustimespune.com/'}
            desc={'Online Media Partner'}
          />
          <MediaSponsor
            src={logoCampusVarta}
            alt={'Campus Varta'}
            href={'https://www.campusvarta.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoCurriculum}
            alt={'Curriculum Mag'}
            href={'https://curriculum-magazine.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoDaisworld}
            alt={'Dais World'}
            href={'https://www.dais.world/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoEdtimes}
            alt={'ED Times'}
            href={'https://edtimes.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoFashionHerald}
            alt={'Fashion Herald'}
            href={'http://fashionherald.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoFestsinfo}
            alt={'fests.info'}
            href={'https://fests.info/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoFrontlines}
            alt={'Front Lines Media'}
            href={'https://frontlinesmedia.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoGrooveNexus}
            alt={'GrooveNexus'}
            href={'https://www.groovenexus.com/'}
            desc={
              'Associate Media Partner and Associate Media Partner of Searock'
            }
          />
          <MediaSponsor
            src={logoHumaraMovie}
            alt={'HumaraMovie'}
            href={'https://www.youtube.com/c/humaramovie/videos'}
            desc={'Associate Media Partner and Media Partner of SHO(R)T'}
          />
          <MediaSponsor
            src={logoIdeapreneur}
            alt={'ideapreneurindia.com'}
            href={'https://www.ideapreneurindia.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoIndiaCollegeFest}
            alt={'India College Fest'}
            href={'https://indiacollegefest.com/#gsc.tab=0'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoIndiaEducation}
            alt={'India Education'}
            href={'https://indiaeducation.net/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoInsightone}
            alt={'Insightone'}
            href={'https://www.instagram.com/insightone_/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoInterviewBuddy}
            alt={'Interview Buddy'}
            href={'https://interviewbuddy.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoKnowafest}
            alt={'Knowafest'}
            href={'https://www.knowafest.com/explore/events'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoLawctopus}
            alt={'Lawctopus'}
            href={'https://www.lawctopus.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoNewsreach}
            alt={'Newsreach'}
            href={'https://newsreach.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoOhCampus}
            alt={'OhCampus'}
            href={'https://ohcampus.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoPaisaPani}
            alt={'PaisaPani'}
            href={'https://paisapani.in/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoRDX}
            alt={'RDX Goa'}
            href={'https://rdxgoa.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoStartupNama}
            alt={'StartupNama'}
            href={'https://www.startupnama.com/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoTheGoan}
            alt={'The Goan Everyday'}
            href={'https://epaper.thegoan.net/'}
            desc={'Associate Media Partner'}
          />
          <MediaSponsor
            src={logoToTheCulture}
            alt={'ToTheCulture'}
            href={'https://www.totheculture.com/'}
            desc={'Associate Media Partner and Media Partner of Sizzle'}
          />
          <MediaSponsor
            src={logoVivaGoa}
            alt={'Viva Goa'}
            href={'https://vivagoamagazine.com/'}
            desc={'Associate Media Partner'}
          />
        </Grid>
      </VStack>
    </>
  );
};

const MediaSponsor = props => {
  return (
    <VStack as={Link} href={props.href}>
      <Image
        src={props.src}
        width={'95%'}
        maxH={'7.5rem'}
        flexGrow={1}
        objectFit={'contain'}
        alt={props.alt}
      />
      <Text align={'center'}>{props.desc}</Text>
    </VStack>
  );
};

const Sponsor = props => {
  return (
    <VStack as={Link} href={props.href}>
      <Image
        src={props.src}
        width={'95%'}
        maxH={'10rem'}
        flexGrow={1}
        objectFit={'contain'}
        alt={props.alt}
      />
      <Text align={'center'}>{props.desc}</Text>
    </VStack>
  );
};

export default Sponsors;
