import React from 'react';
import { Box, Link, Image, Heading } from '@chakra-ui/react';
import chest from '../assets/images/event-chest.png';
import eventBg from '../assets/images/event-bg.png';
import eventFrame from '../assets/images/event-frame.png';
import categoryTitle from '../assets/images/category-title.png';
import button from '../assets/images/button.png';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';

import events from './events.json';
import { useState } from 'react';
import './Event.css';

export default function Events() {
  const Overlay = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [eventCategory, setEventCategory] = useState(0);
  const [event, setEvent] = useState(0);
  return (
    <Box position={'relative'} height={'100%'} id='events'>
      <Heading fontFamily='BlackChancery' textAlign={'center'}>
        Events
      </Heading>
      <Box
        position='absolute'
        top={{ base: '3vh', md: '35%' }}
        left={{ base: '5vw', md: '5%' }}
      >
        <Link
          display={'flex'}
          flexDirection={{ base: 'row', md: 'column' }}
          alignItems={'center'}
        >
          <Image
            onClick={() => {
              onOpen();
              setEventCategory(0);
            }}
            h={{ base: '100px', md: '150' }}
            src={chest}
            _hover={{ transform: 'scale(1.1)' }}
          />
          <Text
            fontFamily={'BlackChancery'}
            fontSize={'1.3rem'}
            align={'center'}
          >
            {events[0].fest}
          </Text>
        </Link>
      </Box>
      <Box
        position='absolute'
        top={{ base: '12vh', md: '60%' }}
        right={{ base: '5vw', md: '5%' }}
      >
        <Link
          display={'flex'}
          flexDirection={{ base: 'row-reverse', md: 'column' }}
          alignItems={'center'}
        >
          <Image
            onClick={() => {
              onOpen();
              setEventCategory(1);
            }}
            h={{ base: '100px', md: '150' }}
            src={chest}
            _hover={{ transform: 'scale(1.1)' }}
          />
          <Text
            fontFamily={'BlackChancery'}
            fontSize={'1.3rem'}
            align={'center'}
          >
            {events[1].fest}
          </Text>
        </Link>
      </Box>
      <Box
        position='absolute'
        top={{ base: '21vh', md: '10%' }}
        left={{ base: '5vw', md: '55%' }}
      >
        <Link
          display={'flex'}
          flexDirection={{ base: 'row', md: 'column' }}
          alignItems={'center'}
        >
          <Image
            onClick={() => {
              onOpen();
              setEventCategory(2);
            }}
            h={{ base: '100px', md: '150' }}
            src={chest}
            _hover={{ transform: 'scale(1.1)' }}
          />
          <Text
            fontFamily={'BlackChancery'}
            fontSize={'1.3rem'}
            align={'center'}
          >
            {events[2].fest}
          </Text>
        </Link>
      </Box>
      <Box
        position='absolute'
        top={{ base: '30vh', md: '55%' }}
        right={{ base: '5vw', md: '45%' }}
      >
        <Link
          display={'flex'}
          flexDirection={{ base: 'row-reverse', md: 'column' }}
          alignItems={'center'}
        >
          <Image
            onClick={() => {
              onOpen();
              setEventCategory(3);
            }}
            h={{ base: '100px', md: '150' }}
            src={chest}
            _hover={{ transform: 'scale(1.1)' }}
          />
          <Text
            fontFamily={'BlackChancery'}
            fontSize={'1.3rem'}
            align={'center'}
          >
            {events[3].fest}
          </Text>
        </Link>
      </Box>
      <Box
        position='absolute'
        top={{ base: '41vh', md: '20%' }}
        left={{ base: '5vw', md: '80%' }}
      >
        <Link
          display={'flex'}
          flexDirection={{ base: 'row', md: 'column' }}
          alignItems={'center'}
        >
          <Image
            onClick={() => {
              onOpen();
              setEventCategory(4);
            }}
            h={{ base: '100px', md: '150' }}
            src={chest}
            _hover={{ transform: 'scale(1.1)' }}
          />
          <Text
            fontFamily={'BlackChancery'}
            fontSize={'1.3rem'}
            align={'center'}
          >
            {events[4].fest}
          </Text>
        </Link>
      </Box>
      <Box
        position='absolute'
        top={{ base: '50vh', md: '10%' }}
        right={{ base: '5vw', md: '60%' }}
      >
        <Link
          display={'flex'}
          flexDirection={{ base: 'row-reverse', md: 'column' }}
          alignItems={'center'}
        >
          <Image
            onClick={() => {
              onOpen();
              setEventCategory(5);
            }}
            h={{ base: '100px', md: '150' }}
            src={chest}
            _hover={{ transform: 'scale(1.1)' }}
          />
          <Text
            fontFamily={'BlackChancery'}
            fontSize={'1.3rem'}
            align={'center'}
          >
            {events[5].fest}
          </Text>
        </Link>
      </Box>
      <Modal
        opacity={0.2}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={'4xl'}
      >
        <Overlay />
        <ModalContent
          background={'none'}
          backgroundImage={eventBg}
          backgroundColor={'transparent'}
          boxShadow={'none'}
          shadow={'1rem'}
          css={{
            outline: 'none',
          }}
          backgroundSize={'100% 100%'}
          w={'55rem'}
          h={{ base: '100vh', md: '37rem' }}
          p={{ base: '1.5rem', md: '3rem' }}
          margin={0}
          maxW={'100vw'}
          maxH={'100vh'}
        >
          <ModalHeader
            backgroundImage={categoryTitle}
            backgroundSize={'100% 100%'}
            width={'fit-content'}
            fontFamily='BlackChancery'
            fontSize='2rem'
            color='#CDC365'
          >
            {events[eventCategory].fest}
          </ModalHeader>
          <ModalBody
            display='flex'
            flexDirection={{ base: 'column', md: 'row' }}
            h={'calc(100% - 4rem)'}
            boxSizing={'border-box'}
          >
            <Box
              display='flex'
              flexDirection={{ base: 'row', md: 'column' }}
              h={'100%'}
              overflowY={{ base: 'clip', md: 'auto' }}
              overflowX={{ base: 'auto', md: 'clip' }}
              flex={'0 0 30%'}
              className={'event-list'}
              minH={{ base: '9.5rem', md: '10.5rem' }}
            >
              {events[eventCategory].events.map((eachEvent, index) => (
                <Box
                  key={index}
                  backgroundImage={eventFrame}
                  backgroundSize={'100% 100%'}
                  w={'12.5rem'}
                  h={'9.5rem'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  onClick={() => setEvent(index)}
                  my={{ base: 0, md: '0.5rem' }}
                  mx={{ base: '0.5rem', md: 0 }}
                  flexShrink='0'
                >
                  <Text color={'white'} fontFamily={'BlackChancery'}>
                    {eachEvent.eventName}
                  </Text>
                </Box>
              ))}
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              flex={'0 0 68%'}
              maxH={{ base: 'calc(100% - 12.5rem)', md: '100%' }}
            >
              <Heading
                fontFamily={'BlackChancery'}
                mx={{ base: '0', md: '1rem' }}
                my={{ base: '0.5rem', md: '0' }}
                px={'1rem'}
                borderBottom={'2px solid black'}
                width={'fit-content'}
              >
                {events[eventCategory].events[event].eventName}
              </Heading>
              <Text
                fontFamily={'BlackChancery'}
                p={'1rem 2rem'}
                fontSize={'1.3rem'}
                flexGrow={1}
                overflowY='auto'
                className={'event-list'}
              >
                {events[eventCategory].events[event].description}
              </Text>
              <Text
                backgroundImage={button}
                backgroundSize={'100% 100%'}
                width={'fit-content'}
                onClick={() => {
                  onClose();
                  setEvent(0);
                }}
                fontFamily='BlackChancery'
                fontSize='1.3rem'
                color='#CDC365'
                padding='0.25rem 0.75rem'
                alignSelf={'flex-end'}
                cursor='pointer'
                transition={'all 0.25s'}
                _hover={{
                  transform: 'scale(1.05)',
                  transition: 'all 0.25s',
                }}
              >
                Back
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
