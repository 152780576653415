import React from 'react';
import { Box, Text, Image, Show, Hide } from '@chakra-ui/react';
import compass from '../assets/images/compass.png';
import compass_crop from '../assets/images/compass-crop.png';
import shield from '../assets/images/shield.png';
import { useState } from 'react';

export default function Dock() {
  const time = '1s';
  const timeShield = '250ms';
  const shieldH = '110px';
  const shieldTop = 'calc(37%)';
  const shieldLeft = 'calc(45%)';

  const [menuOpen, setMenuOpen] = useState(false);

  function scrollToView(id) {
    setMenuOpen(false);
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <Hide below='md'>
        <Box
          display='block'
          position='absolute'
          bottom='0'
          left='0'
          bg='transparent'
          overscrollY={'contain'}
        >
          <Box
            className='group'
            zIndex='90'
            bg='transparent'
            w='300px'
            h='300px'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Box
              w='250px'
              h='250px'
              zIndex='100'
              bgSize='250px 250px'
              bgImage={compass_crop}
              bgRepeat='no-repeat'
              bgPosition='center'
              position='absolute'
              left='0'
              bottom='0'
            />
            <Box
              w='400px'
              position='absolute'
              left={shieldLeft}
              top={shieldTop}
              _groupHover={{
                transform: 'rotate(-20deg)',
                transitionDuration: `${time}`,
              }}
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='94px'
                _groupHover={{
                  transform: 'translate(120px) rotate(20deg)',
                  transitionDuration: `${time}`,
                }}
                transitionDuration={time}
                onClick={() =>
                  (window.location.href =
                    'mailto:events@bits-waves.org?subject=Event%20Query%20Waves%202022')
                }
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Contact Us
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h={shieldH}
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='400px'
              position='absolute'
              left={shieldLeft}
              top={shieldTop}
              _groupHover={{
                transform: 'rotate(-70deg)',
                transitionDuration: `${time}`,
              }}
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='94px'
                _groupHover={{
                  transform: 'translate(120px) rotate(70deg)',
                  transitionDuration: `${time}`,
                }}
                transitionDuration={time}
                onClick={() =>
                  (window.location.href = 'https://register.wavesbits.org/')
                }
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Register
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h={shieldH}
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='400px'
              position='absolute'
              left={shieldLeft}
              top={shieldTop}
              _groupHover={{
                transform: 'rotate(-10deg)',
                transitionDuration: `${time}`,
              }}
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='94px'
                _groupHover={{
                  transform: 'translate(250px) rotate(10deg)',
                  transitionDuration: `${time}`,
                }}
                transitionDuration={time}
                onClick={() => scrollToView('sponsors')}
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Sponsors
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h={shieldH}
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='400px'
              position='absolute'
              left={shieldLeft}
              top={shieldTop}
              _groupHover={{
                transform: 'rotate(-80deg)',
                transitionDuration: `${time}`,
              }}
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='94px'
                _groupHover={{
                  transform: 'translate(250px) rotate(80deg)',
                  transitionDuration: `${time}`,
                }}
                transitionDuration={time}
                onClick={() => scrollToView('about')}
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  About Us
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h={shieldH}
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='400px'
              position='absolute'
              left={shieldLeft}
              top={shieldTop}
              _groupHover={{
                transform: 'rotate(-45deg)',
                transitionDuration: `${time}`,
              }}
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='94px'
                _groupHover={{
                  transform: 'translate(250px) rotate(45deg)',
                  transitionDuration: `${time}`,
                }}
                transitionDuration={time}
                onClick={() => scrollToView('events')}
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Events
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h={shieldH}
                  src={shield}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Hide>
      <Show below='md'>
        <Box
          display='flex'
          position='absolute'
          style={
            menuOpen
              ? {
                  transform: 'translate(0px, -35vh)',
                  transitionDuration: `${time}`,
                }
              : {}
          }
          transitionDuration={time}
          top='calc(100vh - 200px)'
          left='calc(50vw - 100px)'
          bg='transparent'
        >
          <Box
            className='group'
            zIndex='90'
            bg='transparent'
            w='200px'
            h='200px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            id='compass-mobile'
          >
            <Box
              w='150px'
              h='150px'
              zIndex='100'
              bgSize='150px 150px'
              bgImage={compass}
              bgRepeat='no-repeat'
              bgPosition='center'
              position='absolute'
              borderRadius='75px'
              onClick={() => {
                setMenuOpen(true);
              }}
            ></Box>
            <Box
              w='170px'
              position='absolute'
              left='calc(50%)'
              style={
                menuOpen
                  ? {
                      transform: 'rotate(-36deg)',
                      transitionDuration: `${time}`,
                    }
                  : {}
              }
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='80px'
                transform='translate(-20px)'
                style={
                  menuOpen
                    ? {
                        transform: 'translate(100px) rotate(36deg)',
                        transitionDuration: `${time}`,
                      }
                    : {}
                }
                transitionDuration={time}
                onClick={() =>
                  (window.location.href =
                    'mailto:events@bits-waves.org?subject=Event%20Query%20Waves%202022')
                }
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Contact Us
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h='100px'
                  objectFit='contain'
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='170px'
              position='absolute'
              left='calc(50%)'
              style={
                menuOpen
                  ? {
                      transform: 'rotate(-108deg)',
                      transitionDuration: `${time}`,
                    }
                  : {}
              }
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='80px'
                transform='translate(-20px)'
                style={
                  menuOpen
                    ? {
                        transform: 'translate(100px) rotate(108deg)',
                        transitionDuration: `${time}`,
                      }
                    : {}
                }
                transitionDuration={time}
                onClick={() =>
                  (window.location.href = 'https://register.wavesbits.org/')
                }
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Register
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h='100px'
                  objectFit='contain'
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='170px'
              position='absolute'
              left='calc(50%)'
              style={
                menuOpen
                  ? {
                      transform: 'rotate(-252deg)',
                      transitionDuration: `${time}`,
                    }
                  : {}
              }
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='80px'
                transform='translate(-20px)'
                style={
                  menuOpen
                    ? {
                        transform: 'translate(100px) rotate(252deg)',
                        transitionDuration: `${time}`,
                      }
                    : {}
                }
                transitionDuration={time}
                onClick={() => scrollToView('sponsors')}
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Sponsors
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h='100px'
                  objectFit='contain'
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='170px'
              position='absolute'
              left='calc(50%)'
              style={
                menuOpen
                  ? {
                      transform: 'rotate(180deg)',
                      transitionDuration: `${time}`,
                    }
                  : {}
              }
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='80px'
                transform='translate(-20px)'
                style={
                  menuOpen
                    ? {
                        transform: 'translate(100px) rotate(-180deg)',
                        transitionDuration: `${time}`,
                      }
                    : {}
                }
                transitionDuration={time}
                onClick={() => scrollToView('about')}
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  About Us
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h='100px'
                  objectFit='contain'
                  src={shield}
                />
              </Box>
            </Box>
            <Box
              w='170px'
              position='absolute'
              left='calc(50%)'
              style={
                menuOpen
                  ? {
                      transform: 'rotate(36deg)',
                      transitionDuration: `${time}`,
                    }
                  : {}
              }
              transitionDuration={time}
              transformOrigin={'left center'}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='80px'
                transform='translate(-20px)'
                style={
                  menuOpen
                    ? {
                        transform: 'translate(100px) rotate(-36deg)',
                        transitionDuration: `${time}`,
                      }
                    : {}
                }
                transitionDuration={time}
                onClick={() => scrollToView('events')}
              >
                <Text
                  position='absolute'
                  zIndex='2'
                  color='white'
                  margin='1.2rem'
                  align='center'
                >
                  Events
                </Text>
                <Image
                  _hover={{
                    transform: 'scale(1.1)',
                    transitionDuration: `${timeShield}`,
                  }}
                  transitionDuration={timeShield}
                  h='100px'
                  objectFit='contain'
                  src={shield}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Show>
    </>
  );
}
