import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import scroll from '../assets/images/scroll.png';
import bg from '../assets/images/bg.jpg';
import logo from '../assets/images/logo.png';
import coins from '../assets/images/coins.png';
import coinR from '../assets/images/coins-right.png';
import coinL from '../assets/images/coins-left.png';
import ropeD from '../assets/images/rope-desk.png';
import ropeP from '../assets/images/rope-ph.png';
import './Home.css';

import About from './About';
import Event from './Event';
import Sponsors from './Sponsors';

export default function Home() {
  /*
    const move_lb = keyframes`
    from { transform: translate(35vw); }
    to { transform: translate(opx); }
    `;
    const animation_lb =  `${move_lb}  3s linear`;

    const move_rb = keyframes`
    from { transform: translate(-35vw); }
    to { transform: translate(0px); }
    `;
    const animation_rb =  `${move_rb}  3s linear`;

    const move_scroll = keyframes`
    from { transform: scaleX(0); }
    to { transform: scaleX(1); }
    `;
    const animation_scroll =  `${move_scroll}  3s linear`;
    */

  return (
    <Box
      w='100vw'
      h='100vh'
      bgSize='100vw 100vh'
      bgImage={bg}
      bgPosition='center'
      bgRepeat='no-repeat'
      display='flex'
      justifyContent='center'
      alignItems='center'
      overflowY='clip'
    >
      <Image
        src={coins}
        h='350px'
        position='absolute'
        top='0'
        left='0'
        display={{ base: 'none', md: 'block' }}
      />
      <Image
        src={coinR}
        position='absolute'
        bottom='0'
        right='0'
        display={{ base: 'block', md: 'none' }}
      />
      <Image
        src={coinL}
        position='absolute'
        bottom='0'
        left='2'
        display={{ base: 'block', md: 'none' }}
      />
      <Image
        src={ropeD}
        position='absolute'
        top='0'
        right='0'
        display={{ base: 'none', md: 'block' }}
      />
      <Image
        src={ropeP}
        position='absolute'
        top='0'
        left='0'
        display={{ base: 'block', md: 'none' }}
      />
      <Flex
        direction={'column'}
        w={{ base: '100%', md: '80%' }}
        h='90%'
        backgroundImage={scroll}
        backgroundSize='100% 100%'
        paddingTop={{ base: '5rem', md: '2rem' }}
        paddingBottom='2.5rem'
        paddingX='2rem'
        maxWidth='60rem'
      >
        <Image
          src={logo}
          h={{ base: '50px', md: '90px' }}
          objectFit='contain'
        />
        <Box overflowY='auto' className='content-container'>
          <About />
          <Event />
          <Sponsors />
          <Box h={'5rem'} display={{ base: 'block', md: 'none' }} />
        </Box>
      </Flex>
    </Box>
  );
}
