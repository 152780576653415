import React from 'react';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';
import Dock from './Components/dock';
import Home from './Components/Home';
import Loader from './Components/Loader';

import './App.css';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box
        overflow='clip'
        display='flex'
        justifyContent='center'
        alignItems='center'
        fontFamily='BlackChancery'
      >
        <Loader />
        <Home />
        <Dock zIndex={1} />
      </Box>
    </ChakraProvider>
  );
}

export default App;
